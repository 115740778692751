import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Box sx={{ paddingY: 20 }} textAlign="center">
        <Typography gutterBottom variant="h2">404: Not Found</Typography>
        <Typography>Sorry, we couldn't find that page!</Typography>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
